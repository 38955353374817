import ScrollMagic from 'scrollmagic';
export function feature(controller , scene){
	const featureNavigation = document.getElementById('featureNavigation');
	if(featureNavigation){
		const featuresElem = document.querySelectorAll('.features');
		for(let i = 0; i < featuresElem.length; i++){
			scene.push(new ScrollMagic.Scene({
					triggerElement: featuresElem[i],
					duration: featuresElem[i].clientHeight + 'px',
				})
				.setClassToggle(featureNavigation.children[i],'active')
				.addTo(controller)
			);
		}
	}
};