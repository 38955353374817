import ScrollMagic from 'scrollmagic';
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min';
import Rellax from 'rellax';
import {pJS} from "particles.js";

export function scrolEvents(controller , scene){
	const siteHeader = document.getElementById('site-header');
    if(siteHeader){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * .56
            })
            .setClassToggle(siteHeader,'active').addTo(controller)
        )
    };
	
		
	const scrollAnimeName = 'scrollAnime';
	const scrollAnimeElem = document.getElementsByClassName(scrollAnimeName);
	if(scrollAnimeElem.length > 0){
		for(let i = 0; i < scrollAnimeElem.length; i++){
			let scrollNode = scrollAnimeElem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scrollNode,
					offset: -300
				})
				.reverse(false)
				.setClassToggle(scrollNode, '_active').addTo(controller)
			)
		}
	}

    if(document.getElementsByClassName('rellax').length > 0){
        const rellax = new Rellax('.rellax',{
            center: true
        });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
        const rellax_no = new Rellax('.rellax-no',{
            center: false
        });
    }

    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
		header: '#site-header',
        offset: 0,
		speed: 1000,
		easing: 'easeInOutQuart',
		speedAsDuration: true,
		durationMin: 1000
    });
	
	const particles = document.getElementById('particles');
	const particlePath = '/wp-content/themes/owners-cloud/assets/pjs/particlesjs-config.json'
	if(particles){
		particlesJS.load('particles', particlePath);
	}
	
	const fixBnr = document.getElementById('fixBnr');
    const keyName = 'visited';
    const keyValue = true;
	
    if(fixBnr){
		if(!sessionStorage.getItem(keyName)){
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: 'body',
					offset: window.innerHeight * 2
				})
				.setClassToggle(fixBnr,'active').addTo(controller)
			)
		}else{
			fixBnr.remove();
		}
    }
	const fixClose = document.getElementById('fixClose');
	if(fixClose){
		fixClose.addEventListener('click', ()=>{
			sessionStorage.setItem(keyName, keyValue);
			fixClose.parentElement.remove();
		});
	}	

	const imageTag = document.querySelectorAll('img');
	imageTag.forEach((el)=>{
		el.draggable = false;
		el.oncontextmenu = ()=> {
			return false;
		};
	});

	const acceptance = document.getElementById('privacyAccept');
	if(acceptance){
		window.addEventListener('load', ()=> {
			const sendBtn = document.getElementsByClassName('wpcf7-submit');
			for(let i = 0; i < sendBtn.length; i++){
				sendBtn[i].disabled = false;
			}
		});
	}
}