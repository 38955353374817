import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

import ScrollMagic from 'scrollmagic';
import {TweenMax} from "gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
import {ScrollMagicPluginIndicator} from "scrollmagic-plugins";
ScrollMagicPluginGsap(ScrollMagic, TweenMax);
TweenMax.defaultOverwrite = false;
ScrollMagicPluginIndicator(ScrollMagic);

export function index(controller , scene){
	const indexswipe = document.getElementById('voiceSwiper');
	if(indexswipe){
		new Swiper(indexswipe, {
			slidesPerView: 'auto',
			loop: true,
			speed: 800,
			centeredSlides: true,
			modules: [Navigation, Pagination, Autoplay],
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			pagination: {
				el: document.getElementById('voiceSwiperPager'),
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + (index + 1) + "</span>";
				},
			},
		});
	}
	
	
	const phoneMockup = document.getElementById('phoneMockup');
	const boxInner = document.querySelectorAll('.calcHeight');
	if(phoneMockup){		
        scene.push(new ScrollMagic.Scene({
                triggerElement: phoneMockup,
                duration: calcHeight(boxInner) + 'px',
                triggerHook: "onLeave",
            })
            .setPin(phoneMockup, {pushFollowers: false})
            //.addIndicators({ name: "phoneMockup" })
            .addTo(controller)
        );
	}
	
	const listData = document.querySelectorAll('.listdata');
	const drawImg = document.getElementById('drawImg');
	const drawCrone = document.getElementById('drawImgClone');
	if(listData.length > 0){
		for(let i = 0; i < listData.length; i++){	
            scene.push(new ScrollMagic.Scene({
                    triggerElement: listData[i],
                })
                //.addIndicators({ name: "listData" })
                .on('enter' , function(e){
					drawImg.children[i].classList.add('active');
					drawCrone.classList.add(`move-${i+1}`);
				})
                .on('leave' , function(e){
					drawImg.children[i].classList.remove('active');
					drawCrone.classList.remove(`move-${i+1}`);
				})
                .addTo(controller)
            );
		}
	}
	
	const follower = document.getElementById('follower');
	const c = document.querySelectorAll(".clickable");
	if(follower){
		let
			fWidth = 80,
			delay = 10,
			mouseX = 0,
			mouseY = 0,
			posX = 0,
			posY = 0;

		TweenMax.to({}, .01, {
			repeat: -1,
			onRepeat: function() {
				posX += (mouseX - posX) / delay;
				posY += (mouseY - posY) / delay;
				TweenMax.set(follower, {
					css: {
						left: posX - (fWidth / 2),
						top: posY - (fWidth / 2)
					}
				});
			}
		});
		document.body.addEventListener("mousemove", function(e){
			mouseX = e.clientX;
			mouseY = e.clientY;
		});
		
		for (let i = 0; i < c.length; i++) {
			c[i].removeEventListener("mouseenter",mouseEnter);
			c[i].removeEventListener("mouseleave",mouseLeave);
			c[i].addEventListener("mouseenter",mouseEnter);
			c[i].addEventListener("mouseleave",mouseLeave);
		}
		
		function mouseEnter(){
			follower.classList.add('active');
		}
		function mouseLeave(){
			follower.classList.remove('active');
		}
	}

};


function calcHeight(el){
	let result = 0;
	el.forEach(item => {
		result += item.clientHeight;
	});
	result -= Math.floor(window.innerHeight/2);
	return result;
}
